import "bootstrap";

import Vue from "vue";
import Raven from "raven-js";
import RavenVue from "raven-js/plugins/vue";

let dsn = document.documentElement.getAttribute("data-sentry-dsn");
let sentry_release = document.documentElement.getAttribute(
    "data-sentry-release",
);
let sentry_environment = document.documentElement.getAttribute(
    "data-sentry-environment",
);
Raven.config(dsn, { release: sentry_release, environment: sentry_environment })
    .addPlugin(RavenVue, Vue)
    .install();

if (USER_ID !== undefined) {
    Raven.setUserContext({
        email: USER_EMAIL,
        id: USER_ID,
    });
}

Raven.context(function () {
    require(["./vue_app"]);
});

// export jQuery to global scope; this is neede for django-session-security
const jquery = require("jquery");
global.$ = jquery;
global.jQuery = global.$;
